.top{
    width: 100%;
    height: 100px;
    background-color: white;
    position: sticky;
    top:0;
    display: flex;
    align-items: center;
    /*font-family: 'Arima Madurai', cursive;
    font-family: 'Dancing Script', cursive; */
    font-family: 'Lora', serif;
    z-index: 999;
}

.topLeft, .topRight {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topCenter {
    flex: 6;

}

.topIcon {
    font-size: 40px;
    margin-right: 10px;
    color: #444;
    cursor: pointer;

}

.topImg {
    width: 140px;
    height: 100px;
    border-radius: 15% ;
    object-fit: cover;
}

.topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.topListItem {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}