.postInfo{
    align-items: flex-start;
    text-align: center;
    margin-top: 10px;
    margin-left: 50px;
    font-family: 'Lora', serif;
    font-size: 15px;
}

.author{
    display: flex;
    flex-direction: column;
    flex:2;
}

.date{
    display: flex;
    flex-direction: column;
    flex:2;
}

.body{
    margin-top: 10px;
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 10px;

    font-family: 'Lora', serif;
    font-size: 20px;
}


p{
    text-align: justify;
}
