.despreNoi{
    display: flex;
}

h1{
    align-items: center;
    line-height: 2em;
    font-family: 'Dancing Script', cursive;
    text-align: center;
    font-size: 70px;
}

h2{
    line-height: 2em;
    font-family: 'Dancing Script', cursive;
    text-align: center;
    font-size: 50px;
}

h3{
    font-family: 'Lora', serif;
    text-align: center;
    font-size: 25px;
}

.half{
    display: flex;
}

.member{
    display: flex;
    flex-direction: column;
    flex: 2;
    margin-top: 50px;
    margin-left: 100px;
    margin-right: 100px;
    margin-bottom: 50px;
}

.profile{
    height: 100%;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10%;
}

.description{
    font-family: 'Lora', serif;
    text-align: justify;
    font-size: 20px;
}

.famDiv{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 100px;
    margin-left: 100px;
    border-top:1px solid lightslategrey;
    border-bottom: 1px solid lightslategrey;
}
.famImg{
    margin-top: 20px;
    margin-bottom: 20px;
    height: 50%;
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10%;

}