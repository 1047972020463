.home{
    display: flex;    
}

.section{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sectionTitle{
    align-items: center;
    line-height: 2em;
    font-family: 'Dancing Script', cursive;
    font-size: 50px;
    text-align: center;
}

.sectionBody{
    margin-top: 5px;
    margin-bottom: 0px;
    margin-left: 100px;
    margin-right: 100px;
    line-height: 2em;
    font-family: 'Lora', serif;
    text-align: justify;
    font-size: 20px;
    overflow-wrap: break-word;
}

.directions{
    display: flex;
}

.direction{
    display: flex;
    flex-direction: column;
    flex: 4;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    align-items: center;
    font-family: 'Lora', serif;
    background-color: #fdfbfb;
    border-radius: 10px;
}

.directionTitle{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 30px;
    border-bottom: 1px solid #a7a4a4;
}

.directionBody{
    margin-left: 40px;
    margin-right: 40px;
    font-size: 20px;
    text-align: justify;
}