.header {
    margin-top: 10%;
}

.headerTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arima Madurai', cursive;
    color: #444;
}

.headerTitleLg{
    position: absolute;
    top: 20%;
    font-size: 500%;
}

.headerImg{
    width: 100%;
    height: 350px;
    margin-top: 8px;
    object-fit: cover;
}