.footer{
    margin-top: 100px;
    align-items: center;
    font-family: 'Arima Madurai', cursive;
    color: #444;
    background-color: honeydew;
    height: 70px;
}

p{
    margin: 50px;
}